import type { PropsWithChildren } from "react";
import { useCallback, useMemo } from "react";

import CancelIcon from "@mui/icons-material/CancelOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import type { Theme } from "@mui/material/styles";
import type { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { DateTime } from "luxon";

import { useEnvironment, useIsProductionDeployment } from "../../context/EnvContext";
import { useLocalStorageClean } from "../../hooks/useLocalStorageClean";

const styles: { [name: string]: SystemStyleObject<Theme> } = {
  bannerBox: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    height: 0,

    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "right",

    backgroundColor: "orange",

    typography: "body2",

    transition: "all 0.5s ease",
  },

  bannerBoxDisplayed: {
    height: "1.5rem",
  },

  appWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: "100vh",

    transition: "all 0.5s ease",
  },

  appWrapperBoxDisplayed: {
    top: "1.5rem",
    height: `calc(100vh - 1.5rem)`,
  },

  bannerText: {
    display: "inline",

    whiteSpace: "nowrap",
    marginLeft: 0.25,
    marginRight: 0.25,
  },

  boldText: {
    fontWeight: "bold",
  },
};

const EnvironmentBannerWrapper = ({ children }: PropsWithChildren<unknown>) => {
  const env = useEnvironment();
  const isProduction = useIsProductionDeployment();
  const [bannerDismissedTime, setBannerDismissedTime] = useLocalStorageClean("bannerDismissedTime");

  const handleDismissBannerClick = useCallback(() => {
    setBannerDismissedTime(DateTime.now().toISO());
  }, [setBannerDismissedTime]);

  const bannerDismissed = useMemo(() => {
    return DateTime.now().hasSame(DateTime.fromISO(bannerDismissedTime), "day");
  }, [bannerDismissedTime]);

  const showBanner = !(bannerDismissed || isProduction);

  if (showBanner) {
    return (
      <>
        <Box sx={[styles.bannerBox, styles.bannerBoxDisplayed]}>
          <Box sx={[styles.bannerText, styles.boldText]}>Warning:</Box>
          <Box sx={styles.bannerText}>Non-production environment</Box>
          <Box sx={[styles.bannerText, styles.boldText]}>{env.Deployment}</Box>
          <IconButton onClick={handleDismissBannerClick} title="Dismiss">
            <CancelIcon />
          </IconButton>
        </Box>
        <Box sx={[styles.appWrapper, styles.appWrapperBoxDisplayed]}>{children}</Box>
      </>
    );
  } else {
    return <>{children}</>;
  }
};

export default EnvironmentBannerWrapper;
